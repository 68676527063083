import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import JwtService from "@/core/services/jwt.service"
import store from '@/core/services/store'
import router from '@/router.js'
import { LOGOUT, REFRESH_TOKEN } from "@/core/services/store/auth.module"

/**
 * Service to call HTTP request via Axios
 */

const env = process.env
const baseUrlApiSass = env.VUE_APP_API_SAAS_ENDPOINT

const ApiService = {
  

  init() {
    Vue.use(VueAxios, axios)
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;

  },

  setTenantConfig(tenantConfig) {
    Vue.axios.defaults.headers.common[
      "hostip"
    ] = tenantConfig.database_ip

    Vue.axios.defaults.headers.common[
      "name"
    ] = tenantConfig.database_name
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      if (error.response.status == 401) {
        // store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
        // store.dispatch(REFRESH_TOKEN).then(()=>{//('Berhasil Refresh Token')}).catch(() => {
        //   store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
        // });
      }
      return error.response
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${baseUrlApiSass}${resource}${slug}`).catch(error => {
      if (error.response.status == 401) {
        // store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
        // store.dispatch(REFRESH_TOKEN).then(()=>{//('Berhasil Refresh Token')}).catch(() => {
        //   store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
        // });
      }
      return error.response
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${baseUrlApiSass}${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${baseUrlApiSass}${resource}${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${baseUrlApiSass}${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // //(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
