import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/registrants",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
        meta: {
          title: 'Vendor Medhop SAAS APP - Dashboard',
          access_right: '1001'
        }
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/view/pages/Builder.vue")
      },
      // ::masters::
      {
        path: "/masters",
        name: "masters",
        redirect: "/masters/settings",
        component: () => import("@/view/pages/masters/Masters.vue"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Data Pendukung - Setting'
        },
        children: [
          // setting
          {
            path: "/masters/settings",
            name: "master-setting",
            component: () => import("@/view/pages/masters/Setting.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - Setting'
            },
          },

          // access-roles
          {
            path: "/masters/user-managements/role-access",
            name: "master-access-roles",
            component: () => import("@/view/pages/masters/user-managements/RoleAccess.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - Hak Akses Peran',
              access_right: '9001'
            },
          },

          // access-users
          {
            path: "/masters/user-managements/user-access",
            name: "master-access-users",
            component: () => import("@/view/pages/masters/user-managements/UserAccess.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - Hak Akses User',
              access_right: '9002'
            },
          },
          // roles
          {
            path: "/masters/roles",
            name: "master-roles",
            component: () => import("@/view/pages/masters/roles/Roles.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - Role',
              access_right: '9027'
            },
          },
          {
            path: "/masters/roles/add",
            name: "master-roles-add",
            component: () => import("@/view/pages/masters/roles/RolesAdd.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - Role',
              access_right: '9028'
            },
          },
          {
            path: "/masters/roles/edit/:id",
            name: "master-roles-edit",
            component: () => import("@/view/pages/masters/roles/RolesUpdate.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - Peran',
              access_right: '9028'
            },
          },
          {
            path: "/masters/roles/detail/:id",
            name: "master-roles-detail",
            component: () => import("@/view/pages/masters/roles/RolesDetail.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - Peran',
              access_right: '9027'
            },
          },

          // users
          {
            path: "/masters/users",
            name: "master-users",
            component: () => import("@/view/pages/masters/users/Users.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - User',
              access_right: '9001'
            },
          },
          {
            path: "/masters/users/add",
            name: "master-users-add",
            component: () => import("@/view/pages/masters/users/Add.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - User',
              access_right: '9002'
            },
          },
          {
            path: "/masters/users/detail/:id",
            name: "master-users-detail",
            component: () => import("@/view/pages/masters/users/Detail.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - User',
              access_right: '9001'
            },
          },
          {
            path: "/masters/users/edit/:id",
            name: "master-users-edit",
            component: () => import("@/view/pages/masters/users/Update.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Data Pendukung - User',
              access_right: '9002'
            },
          },
        ]
      },

      // registrants
      {
        path: "/registrants",
        name: "registrants",
        redirect: "/registrants/list",
        component: () => import("@/view/pages/registrants/Registrant.vue"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Pendaftar'
        },
        children: [
          {
            path: "/registrants/list",
            name: "registrants-list",
            component: () => import("@/view/pages/registrants/List.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
          {
            path: "/registrants/add",
            name: "registrants-add",
            component: () => import("@/view/pages/registrants/Add.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
          {
            path: "/registrants/edit/:id",
            name: "registrants-edit",
            component: () => import("@/view/pages/registrants/Edit.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
          {
            path: "/registrants/tenant-information/:id",
            name: "registrants-tenant-information",
            component: () => import("@/view/pages/registrants/TenantInformation.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
          {
            path: "/registrants/detail/:id",
            name: "registrants-detail",
            component: () => import("@/view/pages/registrants/Detail.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
        ]
      },

      // tenants
      {
        path: "/tenants",
        name: "tenants",
        redirect: "/tenants/list",
        component: () => import("@/view/pages/tenants/Tenant.vue"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Pendaftar'
        },
        children: [
          {
            path: "/tenants/list",
            name: "tenants-list",
            component: () => import("@/view/pages/tenants/List.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
          {
            path: "/tenants/add/:id",
            name: "tenants-add",
            component: () => import("@/view/pages/tenants/Add.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
          {
            path: "/tenants/edit/:id",
            name: "tenants-edit",
            component: () => import("@/view/pages/tenants/Edit.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
          {
            path: "/tenants/detail/:id",
            name: "tenants-detail",
            component: () => import("@/view/pages/tenants/Detail.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Pendaftar'
            },
          },
        ]
      },

      // databases
      {
        path: "/databases",
        name: "databases",
        redirect: "/databases/list",
        component: () => import("@/view/pages/databases/Database.vue"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Database'
        },
        children: [
          {
            path: "/databases/list",
            name: "databases-list",
            component: () => import("@/view/pages/databases/List.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Database'
            },
          },
          {
            path: "/databases/add",
            name: "databases-add",
            component: () => import("@/view/pages/databases/Add.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Database'
            },
          },
          {
            path: "/databases/edit/:id",
            name: "databases-edit",
            component: () => import("@/view/pages/databases/Edit.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Database'
            },
          },
          // {
          //   path: "/databases/detail/:id",
          //   name: "databases-detail",
          //   component: () => import("@/view/pages/databases/Detail.vue"),
          //   meta: {
          //     title: 'Vendor Medhop SAAS APP | Database'
          //   },
          // },
        ]
      },

      // billings
      {
        path: "/billings",
        name: "billings",
        redirect: "/billings/list",
        component: () => import("@/view/pages/billings/Billing.vue"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Billing'
        },
        children: [
          {
            path: "/billings/list",
            name: "billings-list",
            component: () => import("@/view/pages/billings/List.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Billing'
            },
          },
          {
            path: "/billings/detail/:id",
            name: "billings-detail",
            component: () => import("@/view/pages/billings/Detail.vue"),
            meta: {
              title: 'Vendor Medhop SAAS APP | Billing'
            },
          },
        ]
      },


      // blankboard
      {
        path: "/blankboard",
        name: "blankboard",
        component: () => import("@/view/pages/Blankboard.vue"),
        meta: {
          title: 'Blankboard'
        }
      },
      // profile
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/view/pages/custom_pages/Profile.vue"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Profil'
        }
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Login"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Login'
        }
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: 'Vendor Medhop SAAS APP | Register'
        }
      }
    ]
  },
  // redirect to 404
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {
      title: 'Vendor Medhop SAAS APP | Page Not Found'
    },
    component: () => import("@/view/pages/error/Error-1.vue")
  },

  // 503
  {
    path: '/access-forbidden',
    name: 'access-forbidden',
    meta: {
      title: 'Vendor Medhop SAAS APP | Access Forbidden'
    },
    component: () => import('@/view/pages/error/Error-7.vue')
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route Middleware
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.access_right)) {
//     let localStorageAccessRight = JSON.parse(localStorage.getItem('access_right_display'))
//     if (localStorageAccessRight != null) {
//       let result = false
//       for (let i = 0; i < localStorageAccessRight.length; i++) {
//         result = false
//         if (localStorageAccessRight[i] == to.meta.access_right) {
//           result = true
//           break
//         }
//       }
//       if (result) {
//         next()
//       } else {
//         next('/access-forbidden')
//       }
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

export default router;
